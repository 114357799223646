import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from "react-router-dom";
import DropzoneUploader from './DropzoneUploader';
import { fetchEditProposalAPI, updateProposalAPI } from "../api/proposals";
import { removeDocument, uploadDocument } from 'src/api/documents';
import { useAuthContext } from "../hooks/useAuthContext";
import ReactQuill from 'react-quill'; 
import { showSuccessToast, showErrorToast } from "src/utils/toastNotifications";
import 'react-quill/dist/quill.snow.css'; 
import '../styles/components/proposalform.css';

const EditProposal = ({ onUpdate, onClose, isModal }) => {
  const { uniqueUrl } = useParams(); 
  const { user, isSubscribed } = useAuthContext();
  const navigate = useNavigate();

  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [existingFile, setExistingFile] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProposalAndDocuments = async () => {
      try {
        const { proposal, documents } = await fetchEditProposalAPI(uniqueUrl);
        
        setValue('title', proposal.title || '');
        setValue('description', proposal.description || '');
        setValue('name', proposal.name || '');
        setValue('email', proposal.email || '');
        setValue('receiveNotifications', !!proposal.email);

        if (documents.length > 0) {
          setExistingFile(documents[0]);
        }
      } catch (error) {
        setError('Failed to load proposal');
      }
    };

    fetchProposalAndDocuments();
  }, [uniqueUrl, setValue]);

  const onSubmit = async (data) => {
    try {
      const updatedProposal = {
        title: data.title,
        description: data.description,
        name: data.name,
        email: data.receiveNotifications ? (user ? user.email : data.email) : '',
      };

      const response = await updateProposalAPI(uniqueUrl, updatedProposal, user.token);

      if (uploadedFile && uploadedFile instanceof File) {
        const formData = new FormData();
        formData.append('file', uploadedFile);
        await uploadDocument(response._id, formData);
      }

      if (existingFile && !uploadedFile) {
        await removeDocument(existingFile._id);
      }

      if (isModal) {
        onUpdate(response);
        onClose();
      } else {
        navigate(`/${response.uniqueUrl || uniqueUrl}`);
      }

      showSuccessToast('Proposal updated successfully');
      reset();
    } catch {
      setError('Failed to update proposal');
      showErrorToast('Failed to update proposal');
    }
  };

  const descriptionValue = watch("description");

  return (
    <div className='form-container'>
      <div className="edit-proposal">
        <h4 style={{ textAlign: 'center' }}>Edit Proposal</h4>
        <form className="edit" onSubmit={handleSubmit(onSubmit)}>
          <div className="proposal-form">
            <label htmlFor="title">Title:</label>
            <input
              id="title"
              type="text"
              {...register('title', { required: 'Title is required' })}
            />
            {errors.title && <span className="error">{errors.title.message}</span>}

            <label htmlFor="description">Description:</label>
            <ReactQuill
              id="description"
              className="quill-editor"
              value={descriptionValue}
              onChange={(value) => setValue('description', value)}
            />
            {errors.description && <span className="error">Description is required</span>}

            {isSubscribed && (
              <DropzoneUploader
                onFileUpload={setUploadedFile}
                initialFile={existingFile}
                canCancel={true}
                onFileRemove={() => setUploadedFile(null)}
              />
            )}

            <label htmlFor="name">Proposed by:</label>
            <input
              id="name"
              type="text"
              placeholder="Your Name (Optional)"
              {...register('name')}
            />

            <div style={{ paddingTop: '5px', display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                {...register('receiveNotifications')}
                style={{ marginRight: '5px' }}
              />
              <label htmlFor="receiveNotifications">Receive Email Notifications</label>
            </div>

            <button type="submit">Update Proposal</button>
            {error && <div className="error">{error}</div>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProposal;