import { faThumbsUp, faThumbsDown, faBan, faHandPointRight} from '@fortawesome/free-solid-svg-icons';


export const HomePageText = {
  welcomeMessage: "Helping Teams Get on the Same Page",
  description: "Streamline your team’s decision-making process by allowing members to share their input asynchronously – No more endless email threads or missed messages. Discover where your team stands on issues with ease and clarity, empowering you to make informed decisions!",
  createProposalLink: "Create Proposal",
  exampleProposalLink: "View an Example Proposal",
  basicsSection: {
    description: "Consensus is a cooperative process in which group members develop and agree to support a decision in the best interest of the whole."
  },
  learnMoreLink: "Learn More"
};

export const STANDARD_CC_FEATURES = [
  "Unlimited Proposals",
  "Simple sharing link",
  "Email notifications on your proposals",
  "Dashboard of all participated proposals",
  "Proposals expire after 30 days",
  "Limit of 20 replies per proposal"
];

export const PRO_CC_FEATURES = [
  "Unlimited Proposals",
  "Simple sharing link",
  "Email notifications on your proposals",
  "Dashboard of all participated proposals",
  "Proposals never expire",
  "Unlimited replies per proposal",
  "Teams",
  "File upload",
  "Email notification on other’s proposals",
];

export const icons = {
  Agree: faThumbsUp,
  Neutral: faHandPointRight,
  Disagree: faThumbsDown,
  Block: faBan
};

export const tooltips = {
  Agree: '<div><h3>Agree</h3><p>Basic alignment with the proposal</p></div>',
  Neutral: '<div><h3>Neutral</h3><p>Not having an opinion either way and agreeing<br/>to go along with the group\'s decision.</p></div>',
  Disagree: '<div><h3>Stand Aside</h3><p>A choice to let the proposal proceed,<br/>while personally not feeling aligned with direction.</p></div>',
  Block: '<div><h3>Block</h3><p>Proposal is disastrous for the group or<br/>doesn\'t align with the group\'s core principles.</p></div>'
};

export const teamTooltips = {
  Edit: 'Edit',
  Create: 'Create Team Proposal',
  Delete: 'Delete',
};

export const passwordCriteria = [
  { key: 'minLength', label: ' 8 Characters' },
  { key: 'uppercase', label: 'A-Z' },
  { key: 'lowercase', label: 'a-z' },
  { key: 'number', label: '0-9' },
  { key: 'specialChar', label: '(!@#$%^&*)' }
];

export const formatDate = (dateString) => {
  if (!dateString || !Date.parse(dateString)) {
    return '';
  }
  const options = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const quillModules = {
  toolbar: [
    [{ size: ['small', 'false', 'large', 'huge'] }],  // Custom size dropdown
    ['bold', 'italic', 'underline'],                // Text formatting
    [{ list: 'ordered' }, { list: 'bullet' }],      // Lists
    ['link'],                                       // Insert links
    ['clean']                                       // Remove formatting
  ],
};

export const quillFormats = [
  'size', 'bold', 'italic', 'underline',
  'list', 'bullet', 'link'
];

export const quillDefaultStyle = {
  fontSize: '35px',  // Default font size for content
};
