import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { VoteCard, VoteSubmitCard, DescriptionCard } from '../components';
import { useProposalsContext } from '../hooks/useProposalContext';
import { useAuthContext } from '../hooks/useAuthContext';
import { useTeamsContext } from '../context/TeamsContext';
import { showSuccessToast, showErrorToast } from 'src/utils/toastNotifications';
import { fetchProposalData, fetchSubmittedVotes, submitNewTableEntry, checkFirstRender } from '../api/proposals';
import '../styles/pages/proposalvote.css';

const ProposalVote = () => {
  const { dispatch } = useProposalsContext();
  const { user, isSubscribed } = useAuthContext();
  const { uniqueUrl } = useParams();
  const { selectedTeam, updateSelectedTeam, clearSelectedTeam } = useTeamsContext();

  const [proposal, setProposal] = useState(null);
  const [submittedVotes, setSubmittedVotes] = useState([]);
  const [teamVotesSubmitted, setTeamVotesSubmitted] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [showFirstRenderMessage, setShowFirstRenderMessage] = useState(false);
  const [loading, setLoading] = useState(true);

  // Helper function for toast notifications
  const notify = (type, key) => {
    type === 'success' ? showSuccessToast(key) : showErrorToast(key);
  };

  const updateVotes = useCallback(async (proposalId) => {
    const votes = await fetchSubmittedVotes(proposalId);
    setSubmittedVotes(votes);
  }, []);

  const fetchProposalDetails = useCallback(async () => {
    setLoading(true);
    try {
      const token = user?.token || null;
      const { proposal, isOwner, documents } = await fetchProposalData(uniqueUrl, token);
      if (!proposal?._id) throw new Error('Invalid response structure');

      setProposal({ ...proposal, documents });
      setIsOwner(isOwner);
      await updateVotes(proposal._id);

      const firstRender = await checkFirstRender(proposal._id);
      setShowFirstRenderMessage(firstRender);
    } catch (error) {
      console.error('Error fetching proposal data:', error);
    } finally {
      setLoading(false);
    }
  }, [uniqueUrl, user, updateVotes]);

  const submitVotesForTeam = useCallback(async () => {
    if (selectedTeam && proposal && !teamVotesSubmitted) {
      setLoading(true);
      try {
        const votePromises = selectedTeam.members.map((member) => {
          const memberVote = {
            name: member.memberName,
            opinion: '',
            comment: '',
            teamId: selectedTeam._id,
          };
          return submitNewTableEntry(proposal._id, memberVote, setSubmittedVotes);
        });

        await Promise.all(votePromises);
        await updateVotes(proposal._id);

        setTeamVotesSubmitted(true);
        notify('success', 'teamVoteSuccess');
      } catch (error) {
        console.error('Error submitting team votes:', error);
        notify('error', 'teamVoteError');
      } finally {
        setLoading(false);
      }
    }
  }, [proposal, selectedTeam, teamVotesSubmitted, updateVotes]);

  useEffect(() => {
    fetchProposalDetails();
  }, [fetchProposalDetails]);

  useEffect(() => {
    submitVotesForTeam();
  }, [submitVotesForTeam]);

  useEffect(() => {
    if (teamVotesSubmitted) {
      clearSelectedTeam();
    }
  }, [teamVotesSubmitted, clearSelectedTeam]);

  const handleNewTableEntry = async (voteData, isOwner) => {
    try {
      const response = await submitNewTableEntry(proposal._id, voteData, setSubmittedVotes, uniqueUrl);

      if (response?.limitReached) {
        notify('error', 'voteLimitError');
        return;
      }

      notify('success', 'voteSuccess');
      await updateVotes(proposal._id);
    } catch (error) {
      console.error('Error submitting new entry:', error);
      notify('error', 'voteError');
    }
  };

  return (
    <div className="proposal-vote-page-container">
      {loading ? (
        <div>Loading...</div>
      ) : proposal ? (
        <>
          <DescriptionCard
            proposal={proposal}
            user={user}
            isOwner={isOwner}
            isSubscribed={isSubscribed}
            documents={proposal.documents}
            dispatch={dispatch}
            updateSelectedTeam={updateSelectedTeam}
            uniqueUrl={uniqueUrl}
            submittedVotes={submittedVotes}
            setSubmittedVotes={setSubmittedVotes}
            showFirstRenderMessage={showFirstRenderMessage}
          />
          <VoteSubmitCard
            proposal={proposal}
            setSubmittedVotes={setSubmittedVotes}
            handleNewTableEntry={handleNewTableEntry}
          />
          <VoteCard
            proposal={proposal}
            submittedVotes={submittedVotes}
            setSubmittedVotes={setSubmittedVotes}
          />
        </>
      ) : (
        <div>No proposal selected</div>
      )}
    </div>
  );
};

export default ProposalVote;
