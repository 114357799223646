import ProductDisplay from "../components/ProductDisplay";
import { useAuthContext } from '../hooks/useAuthContext';

export default function Subscribe() {
  const { handleCheckout, checkoutLoading } = useAuthContext();

  
  return (
    <div>
      <ProductDisplay
        handleCheckout={handleCheckout}
        loading={checkoutLoading}
      />
    </div>
  );
}


